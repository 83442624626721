
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  cursor: pointer;

  >* {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    inset: 0;
  }
}

.back {
  object-fit: cover;
  filter: blur(10px);
  inset: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
}

.front {
  object-fit: contain;
  transform: rotate(var(--angle)) scale(0.75);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
