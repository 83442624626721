
.app {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .inner {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .inner2 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .inner3 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    >* {
      flex-grow: 1;
    }
  }
}

.terminal {
  background-color: black;
}
