
.file-picker {
  background-color: var(--color-file-picker);

  width: 250px;

  --filepicker-gap: 0.5rem;
  --filepicker-padding: 0.5rem;

  --rotcont-gap: var(--filepicker-gap);
  // text height + padding
  --rotcont-height: calc(1em + 2*var(--filepicker-padding));

  >ul {
    width: 100%;

    padding: var(--filepicker-gap);
  }

  >ul>li {
    width: 100%;

    cursor: pointer;

    display: flex;
    align-items: start;
    justify-content: start;

    background-color: rgba(255,255,255,0);
    transition: background-color 100ms;

    padding: var(--filepicker-padding);
    border-radius: 3px;

    &:hover {
      background-color: rgba(255,255,255,0.1);
    }
  }
}
