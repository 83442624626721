
.code-editor {
  flex-grow: 1;
  position: relative;

  .code-container {
    position: absolute;
    inset: 0;
    overflow-y: scroll;
    overflow-x: auto;

    >* {
      --rotcont-gap: 0rem;
      --rotcont-height: calc(1em);

      font-family: var(--font-family-monospace);
      padding: 0.5rem;
      position: absolute;

      top: 0;
      left: 0;
      right: 0;

      min-height: 100%;
    }
  }

  .the-code {
    color: rgba(255,0,0,0.0);
    caret-color: white;

    &:focus {
      outline: none;
    }

    span {
      // For some reason content editable on chrome inserts spans with a solid
      // background so we need to overwrite it
      background-color: transparent !important;
    }
  }

  .the-visual-code {
    user-select: none;
  }

  .line {
    display: block;
    font-family: inherit;
  }
}
